<template>
  <div class="home pa-2" style="overflow: auto; height: inherit" >
    <v-row style="height:100%">
      <v-col cols="12">
        <v-btn @click="$refs.zipemail.click()">test upload</v-btn>
        <input style="display:none;" type="file" ref="zipemail" @change="handleUploadZip" accept=".zip">
      </v-col>
      <v-col cols="4">
        <vFileInputV2 :model="testModel" :fieldName="'file'" :field="{}" />
      </v-col>
      <v-col cols="12" class="px-12 mt-2" style="height:100%;">
        <mediathequeComponent />
      </v-col>
      <v-col cols="12" id="testContent">
        <v-btn @click="openDialogForm">Test Form</v-btn>
        <FormDialogComponent  v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'"/>
      </v-col>
      <!-- <v-col cols="12" style="height:1000px" class="test">
        
      </v-col> -->
      <v-col cols="4">
        <countdownCircle :config="config"/>
      </v-col>
      <v-col cols="4">
        <countdownCircle :config="config2"/>
      </v-col>
      <v-col cols="4">
        <rankingCoachs :ranking="rankings" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import vFileInputV2 from "../components/forms/inputs/v-file-input-v2.vue";
import countdownCircle from "../components/countdownCircle.vue";
import rankingCoachs from "../components/rankingCoachs.vue";
import FormComponent from "@/components/forms/FormComponent";
import varMenu from '@/components/varMenu'
import exportModelComponent from '@/components/dialogs/exportModelComponent'
import vDateRangePicker from '@/components/forms/inputs/v-daterange-picker'
import dialogStatsContactComponent from '@/components/dialogs/dialogStatsContactComponent'
import mixins from '@/mixins/mixins'
import getForm from "@/mixins/mixins";
import DonutComponent from '@/components/graphs/DonutComponent'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import GenericDataService from '@/services/GenericDataService'
import vCategoryactivityPicker from '../components/forms/inputs/v-categoryactivity-picker.vue'
import mediathequeComponent from "../components/mediathequeComponent/mediathequeComponent.vue";
import vCustomLanding from "../components/forms/inputs/v-custom-landing.vue";
export default {
  name: "TiboTest",
  mixins: [mixins, getForm],
  components: {
    vDateRangePicker,
    FormDialogComponent: () =>
      import("@/components/dialogs/FormDialogComponent"),
    DonutComponent,
    dialogStatsContactComponent,
    varMenu,
    exportModelComponent,
    vCategoryactivityPicker,
    FormComponent,
    countdownCircle,
    rankingCoachs,
    vCustomLanding,
    mediathequeComponent,
    vFileInputV2
  },
  data() {
    return {
      testModel: {
        file: null,
      },
        rankings : [
          {
            id:5,
            firstname: "Donovan",
            lastname: "Laget"
          },
          {
            id:4,
            firstname: "Thibault",
            lastname: "Richy"
          },
          {
            id:3,
            firstname: "Eric",
            lastname: "Legrand"
          },
          {
            id:1,
            firstname: "Baptiste",
            lastname: "Peccate"
          },
          {
            id:2,
            firstname: "Nicolas",
            lastname: "Marietta"
          },
          {
            id:89,
            firstname: "Jean",
            lastname: "Dujardin"
          },
          {
            id:61,
            firstname: "Jean",
            lastname: "Michel"
          }
        ],
        // events renvoyés par le timer:
        // timerStartOffset : event envoyé au début de l'offset si un offset de start est sétté
        // timerStart : event envoyé au début du timer
        // timerReset : event au reset du timer
        // timerPause : event a la pause
        // timerUnpause : event a la fin d'une pause
        // timerEnd : event a la fin du timer
        config : {
            id : "", //id unique pour éviter les pbms si plusieurs timers dans la page (peut etre vide si un timer sur la page)
            duration : "45", // durée du timer en minutes
            offsetStart : 0, // offset avant que le timer commence en secondes
            color: "#6DD5EF", // couleur du texte et du timing
            txt: "Let's go !", // txt a coté du timer
            break: { // breaks : permet de changer la couleur et le texte du timer a certains moments
                5:{ // la clé définit la minute a laquelle la couleur et le texte change
                    color: "#FC7640",
                    txt: "Last Chance !"
                }
            },
            pauseConfig: { // configuration de la couleur et texte du timer lors de la pause
                color: "#D94444", 
                txt: "Stop !"
            }
        },
        config2 : {
              id : "UixKnMeSwH",
              duration : "15",
              offsetStart : 0,
              color: "#7AC55B",
              txt: "Have a break :)",
              break: {},
              pauseConfig: {
                  color: "#D94444",
                  txt: "Stop !"
              }
          },
        api :process.env.VUE_APP_API_URL,
          openExportDialog: false,
          type:'DonutComponent',
          dataSet: {
            datasets: [{
              label: 'Succès',
              backgroundColor: ['#ffcc56','#fe6383','#ff9f40'],
              borderColor:['rgba(0,0,0,0)', 'rgba(0,0,0,0)', 'rgba(0,0,0,0)'],
              data: [120, 120, 120],
            }],
            labels:['BMW MINI Le Mans', 'BMW Laval', 'BMW Alençon']
          },
          indexSelected:[],
          variables: [],
          show:false,
          text: "",
          cursorPosition:0,
          srcInput:{},
          isLeft: false,
          form: null,
          action: null,
          form:null,
          formUrl:null,
          testPopup:false,
          testUpload:[]
    };
  },
  created() {
    
  },
  mounted(){
    
  },
  computed: {
    mainOrg: function () {
      return this.organizations.find((e) => e.id == this.orgId);
    },

    currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
  methods: {
    handleUploadZip(event) {
      this.$refs.zipemail.files.forEach(element =>
          this.testUpload.push(element)
      );
      this.uploadZip();
    },
    uploadZip(){
      this.testUpload.forEach(element => {
          if (element) {
              this.errorFile = ""
              const reader = new FileReader
              const vm = this
              reader.onload = e => {
                  let jsonData = {
                      'title': element.name,
                      'base64': e.target.result
                  };
                  GenericDataService.postData("template/uploadZipEmail", jsonData).then((response) => {
                  });
              }
              reader.readAsDataURL(element)
          } else {
              this.errorFile = this.$t("selectImageError")
          }
      });
    },
    openDialogForm(){
        this.formUrl = '/landingpage/getFormLP2'
        this.getForm('/landingpage/getFormLP2')
    },
    submit(){
      
    },
    reset(){

    },
    dlTest(){
      GenericDataService.getData('retroplanning/22/getExport').then((response) => {
        
      })
    },
    openTestMenu(){
      this.$refs.varMenu.$refs.launcher.$el.click()
    },
    inputVariable(e){
      if(e.key == '{'){
        this.show = true
        this.cursorPosition = e.srcElement.selectionStart
        this.srcInput = e.srcElement
      }
    },
    addVal($event) {
      let firstPart = this.text.substring(0, (this.cursorPosition+1))
      let secondPart = this.text.substring(this.cursorPosition+1)
      firstPart = firstPart.replace(/{+$/, $event)
      let count = firstPart.length;
      this.text = firstPart+secondPart
      setTimeout(() => {
          const ctrl =  document.getElementById('inputVar')
          if (ctrl.setSelectionRange) {
            ctrl.focus();
            ctrl.setSelectionRange(count, count);
            //ctrl.select();
          // IE8 and below
          } else if (ctrl.createTextRange) {
            var range = ctrl.createTextRange();
              range.collapse(true);
              range.moveEnd('character', count);
              range.moveStart('character', count);
              range.select();
          }
      }, 50);
      

      
      
    },
    selectItem(item){
      if(!this.indexSelected.includes(item.index)){
        this.indexSelected.push(item.index);
        this.dataSet.datasets[0].backgroundColor[item.index] = this.shadeColor(this.dataSet.datasets[0].backgroundColor[item.index], 'darker')
        this.$refs.graph.reRender()
      } else {
        let indexToDelete = this.indexSelected.findIndex((e) => e == item.index);
        this.indexSelected.splice(indexToDelete, 1);
        this.dataSet.datasets[0].backgroundColor[item.index] = this.shadeColor(this.dataSet.datasets[0].backgroundColor[item.index], 'lighter')
        this.$refs.graph.reRender()
      }
      
    },
    shadeColor(color, mode) {
        let percent = 0;
        switch (mode) {
            case 'lighter':
                percent = 20
                break;
            case 'darker':
                percent = -20
                break;
        }
        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R<255)?R:255;  
        G = (G<255)?G:255;  
        B = (B<255)?B:255;  

        var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

        return "#"+RR+GG+BB;
    },
  },
  // created() {
  // GenericDataService.getData('/template/getAvailableVariables').then((response) => {
  //           this.variables = response.data.data
  //       })
  //   //  GenericDataService.getList(100).then((response) => {
  //   //     if(response.data.success){
  //   //         const array = response.data.data.map((item) => {
  //   //           return {
  //   //             id: item.id,
  //   //             nom: item.nom,
  //   //             status: item.status,
  //   //             code: item.code,
  //   //             date_import: item.date_import
  //   //           }
  //   //         })
  //   //         this.list = array
  //   //         const filters = response.data.data.map((item) => {
  //   //           return item.status

  //   //         })
  //   //         const uniq = [...new Set(filters)];
  //   //         this.status = uniq
  //   //         this.loading = false
  //   //     }
  //   // })
  // },
  watch: {
    picker: function (val) {
      this.$vuetify.theme.themes.light.primary = val;
    },
  },
};
</script>
<style lang="scss">
.workflow {
  height:500px;
  width: 100%;
}
.organizations {
  max-width: 250px;
}

.container {
  height: auto;
  overflow: auto;
}
</style>
