<template>
    <div class="home pa-2" style="overflow: auto; height: inherit" >
        <v-row style="height:100%">
            <v-col cols="12">
                <div>
                    <div>
                        <div>
                            <v-text-field label="Titre du formulaire" v-model="formTitle" solo hide-details />
                        </div>
                        <v-btn type="submit" class="mt-2" @click="handleSubmit">Créer</v-btn>
                    </div>
                    <div v-if="formCreated">
                        <h2>Formulaire créé avec succès !</h2>
                        <p>ID du formulaire: {{ createdFormId }}</p>
                        <p>URL d'édition du formulaire: <a :href="formEditUrl" target="_blank">{{ formEditUrl }}</a></p>
                        <iframe
                          :src="formEditUrl"
                          width="100%"
                          height="800px"
                          frameborder="0"
                          style="border: none;"
                          allowfullscreen
                          webkitallowfullscreen
                          mozallowfullscreen
                          oallowfullscreen
                          msallowfullscreen
                          ></iframe>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import { createForm, getFormUrl } from '@/jotform';

export default {
    name: "DonoTest",
    components: {
    },
    data() {
        return {
            formTitle: '',
            formCreated: false,
            createdFormId: '',
            formEditUrl: ''
        };
    },
    methods: {
        async handleSubmit() {
            const form = {
                properties: {
                    title: this.formTitle,
                }
            };
            try {
                // Créer le formulaire
                const createdForm = await createForm(form);
                this.createdFormId = createdForm.id;

                // Récupérer l'URL d'édition du formulaire
                const editUrl = await getFormUrl(this.createdFormId);
                this.formEditUrl = "https://www.jotform.com/build/" + this.createdFormId;
                this.formCreated = true;
            } catch (error) {
                console.error('Erreur lors de la création du formulaire:', error);
            }
        },
    }
};
</script>

<style lang="scss">
</style>
