<template>
    <v-dialog v-model="openDialog" width="800" persistent>
        <v-card>
            <v-toolbar flat dark class="bg-gradient" height="64">
                <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="$emit('close')"><v-icon small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title><h3 class="white--text">{{$t('columnsChoice')}}</h3></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined @click="openDialogConfirm">{{$t('apply')}}</v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
                <v-row>
                    <v-col cols="12" v-if="errorNoColumn">
                        <v-alert class="mt-0 mb-0" border="left" color="error" text>
                            {{$t('noColumnSelectedError')}}
                        </v-alert>
                    </v-col>
                    <v-col cols="6">
                        <span class="label black--text"><b>{{$t('searchInFieldsLabel')}}</b></span>
                        <v-text-field v-model="searchInList" @input="filterList" :placeholder="$t('searchspecificcolumn')" solo flat dense class="input-form mt-2"></v-text-field>
                        <div class="mt-2 listFields">
                            <div class="listFieldsContent">
                                <div v-for="(categoryContent, category) in data" class="mb-4">
                                    <span class="label black--text"><b>{{$t(category)}}</b></span>
                                    <v-checkbox v-for="(field, index) in data[category]"  v-show="!field.hide" :key="'field_'+field.value" hide-details v-model="checkboxes['field_'+field.value]" :label="field.text" @change="toggleValue(field)" dense></v-checkbox>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="6" v-if="selectedFields.length > 0" :class="dragedElem ? 'dragging' : ''">
                        <div 
                            :class="handleDropClasses(field)"
                            v-for="(field, index) in selectedFields" 
                            :key="field.value"
                            draggable
                            @dragstart.self="pickupElem($event, field, index);"
                            @dragover.prevent="showDropPlace($event, field, index);"
                            @dragenter.prevent
                            @drop="moveElem($event, field, index);"
                            @dragend.prevent="dragEndClear();">
                            <div
                                class="selectElement list__elem pa-2 mb-1"
                                :class="{
                                'selectElement list__elem--is-dragged': dragedElem && field.value === dragedElem.value
                                }"
                            >
                                <span>
                                    <v-icon small class="mr-2">$gripvertical_s</v-icon>
                                    {{(field.text)}}
                                </span>
                                <v-icon small class="mr-2" @click="removeSelected(field)">$close</v-icon>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="6" v-else>
                        <v-alert class="mt-3" border="left" color="info" text>
                        {{$t('selectcolumnstoexport')}}
                    </v-alert>
                    </v-col>
                    <v-dialog v-model="openConfirmDialog" width="600" persistent>
                        <v-card>
                            <v-toolbar flat dark class="bg-gradient" height="64">
                                <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="openConfirmDialog = false"><v-icon small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                                <v-spacer></v-spacer>
                                <v-toolbar-title><h3 class="white--text">{{$t('recordColumnModel')}}</h3></v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn outlined @click="confirmColumns">{{$t('apply')}}</v-btn>
                            </v-toolbar>
                            <v-card-text class="pt-4">
                                <v-checkbox hide-details v-model="registerModel" :label="$t('makemodelfromcolumns')"  dense></v-checkbox>
                                <div v-if="registerModel" class="mt-3">
                                    <span class="label black--text"><b>{{$t('modelName')}} <span class="red--text"> *</span> </b> <span v-if="errorModelName" class="red--text"> {{$t('requiredfield')}}</span></span>
                                    <v-text-field v-model="modelName" :placeholder="$t('modelName')" required solo flat dense class="required input-form mt-2"></v-text-field>

                                    <span class="label black--text"><b>{{$t('modelVisibility')}} <span class="red--text"> *</span> <span v-if="errorModelVisibility" class="red--text"> {{$t('requiredfield')}}</span></b></span>
                                    <v-select v-model="modelVisibility" :items="['onlyme', 'my_entity', 'my_network']" :placeholder="$t('modelVisibility')" required solo flat dense class="required input-form mt-2">
                                        <template v-slot:selection="data">
                                            <span>
                                                {{ $t(data.item) }}
                                            </span>
                                        </template>
                                        <template v-slot:item="{item, attrs, on}">
                                            <v-list-item v-on="on" v-bind="attrs">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-row no-gutters align="center">
                                                            {{ $t(item)}}
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "exportModelComponent",
    props: ['openDialog'],
    data() {
        return {
            errorNoColumn: false,
            errorModelName : false,
            errorModelVisibility: false,
            registerModel: false,
            modelName: "",
            modelVisibility: "onlyme",
            openConfirmDialog: false,
            checkboxes: {},
            searchInList: "",
            data : {
                identity: [
                    {
                        text: this.$t("lexxy_id"),
                        value: "id",
                        hide: false
                    },
                    {
                        text:this.$t("external_id"),
                        value: "uniq_id",
                        hide: false
                    },
                    {
                        text: this.$t("civility"),
                        value: "civility",
                        hide: false
                    },
                    {
                        text: this.$t("firstname"),
                        value: "firstname",
                        hide: false
                    },
                    {
                        text: this.$t("name"),
                        value: "name",
                        hide: false
                    },
                    {
                        text: this.$t("contactType"),
                        value: "type",
                        hide: false
                    }
                ],
                coords: [
                    {
                        text: this.$t("lexxy_id"),
                        value: "id",
                        hide: false
                    },
                    {
                        text:this.$t("external_id"),
                        value: "uniq_id",
                        hide: false
                    },
                    {
                        text: this.$t("civility"),
                        value: "civility",
                        hide: false
                    },
                    {
                        text: this.$t("firstname"),
                        value: "firstname",
                        hide: false
                    },
                    {
                        text: this.$t("name"),
                        value: "name",
                        hide: false
                    },
                    {
                        text: this.$t("contactType"),
                        value: "type",
                        hide: false
                    }
                ]
            },
            selectedFields:[],
            dragedElem: null,
            overElem: null
        }
    },
    computed: {
        handleDropClasses(item) {
            return item => {
                if (!this.overElem || !this.overElem.value) {
                    return "";
                }
                if (
                    this.overElem.value === item.value &&
                    item.order < this.dragedElem.order
                ) {
                    return "drop-place drop-place--before";
                }
                if (
                this.overElem.value === item.value &&
                item.order > this.dragedElem.order
                ) {
                    return "drop-place drop-place--after";
                }
            };
        }
    },
    methods: {
        openDialogConfirm(){
            if(this.selectedFields.length > 0)
                this.openConfirmDialog = true   
            else {
                this.errorNoColumn = true
                setTimeout(() => {
                    this.errorNoColumn = false
                }, 2000);
            }
        },
        confirmColumns(){
            if(this.registerModel && (this.modelName == "" || this.modelVisibility == "")){
                if(this.modelName == "")
                    this.errorModelName = true;
                if(this.modelVisibility == "")
                    this.errorModelVisibility = true;

                setTimeout(() => {
                    this.errorModelName = false
                    this.errorModelVisibility = false
                }, 2000);
            } else {
                //TODO : envoyer les datas au parent
            }
        },
        filterList(){
            if(this.searchInList.length > 2){
                for( const category in this.data){
                    this.data[category].forEach((field, indexField) => {
                        if(!field.text.includes(this.searchInList) && !field.value.includes(this.searchInList))
                            field.hide = true;
                    })
                }
            } else {
                this.resetHidding();
            }
        },
        resetHidding(){
            for( const category in this.data){
                this.data[category].forEach((field, indexField) => {
                    field.hide = false;
                })
            }
        },
        toggleValue(field){
            const name = "field_"+field.value;
            if(this.checkboxes[name]){
                this.selectedFields.push(field);
                field.order = this.selectedFields.length;
            } else {
                let index = this.selectedFields.findIndex((e) => e.value == field.value);
                delete field.order;
                this.recalculateOrder();
                this.selectedFields.splice(index, 1);
            }
        },
        removeSelected(field){
            const name = "field_"+field.value;
            this.checkboxes[name] = false;
            let index = this.selectedFields.findIndex((e) => e.value == field.value);
            delete field.order;
            this.selectedFields.splice(index, 1);
            this.recalculateOrder();
        },
        recalculateOrder(){
            this.selectedFields.forEach((element, index) => {
                element.order = index + 1
            });
        },
        dragEndClear() {
            this.dragedElem = null;
            this.overElem = null;
        },
        pickupElem(event, elem, index) {
            event.dataTransfer.dropEffect = "move";
            this.dragedElem = { ...elem };
        },
        showDropPlace(event, elem, index) {
        if (elem.value !== this.dragedElem.value) {
            this.overElem = { ...elem };
        } else {
            this.overElem = null;
        }
        },
        moveElem(event, elem, index) {
            this.selectedFields = this.selectedFields.filter((e) => e.value !== this.dragedElem.value);
            this.selectedFields.splice(index, 0, { ...this.dragedElem });
            this.selectedFields.forEach((item, index) => (item.order = index + 1));
        }
    },
}
</script>
<style lang="scss">
.dragging {
    cursor: grabbing !important;
}
    .selectElement {
        background-color: #EEE;
        border-radius:5px;
        color: black;
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
    .list__elem {
        cursor: grab;
        border: 1px solid transparent;
        padding: 5px;
        margin-bottom: 5px;
        transition: all 0.3s ease;
    }
    .list__elem--is-dragged {
        cursor: grabbing !important;
        opacity: 0.7;
        border: 1px dashed skyblue;
    }
    .listFields {
        max-height:200px;
        overflow-y:scroll;
        .listFieldsContent {
            overflow:hidden;
        }
    }
    .drop-place {
        position: relative;
        transition: all 0.3s ease;
    }
    .drop-place--before {
        padding-top: 40px;
    }
    .drop-place--after {
        padding-bottom: 40px;
    }
    .drop-place--before:before {
        position: absolute;
        top: 5px;
        left: 0;
        text-align: center;
        border-radius: 5px;
        content: "Déposez ici";
        width: 100%;
        padding: 7px 0;
        opacity: 0.7;
        border: 1px dashed skyblue;
        box-shadow: inset 0px 0px 5px 2px var(--v-success-base);
    }
    .drop-place--after:after {
        position: absolute;
        bottom: 5px;
        left: 0;
        border-radius: 5px;
        text-align: center;
        content: "Déposez ici";
        width: 100%;
        padding: 7px 0;
        opacity: 0.7;
        border: 1px dashed skyblue;
        box-shadow: inset 0px 0px 5px 2px var(--v-success-base);
    }
</style>