import axios from 'axios';

const API_KEY = '1c83e2e7dfed406120c036cf4dac72e1';
const BASE_URL = 'https://eu-api.jotform.com';

const jotformService = axios.create({
    baseURL: BASE_URL,
    params: {
        apiKey: API_KEY,
    },
});

export const createForm = async (form) => {
    const response = await jotformService.post('/form', form);
    console.log(response);
    return response.data.content;
};

export const getFormUrl = async (formId) => {
    const response = await jotformService.get(`/form/${formId}`);
    return response.data.content.url;
};